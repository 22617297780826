import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import '@/assets/reset.css'
import '@/assets/iconfont.css'

// import 'animate.css';

Vue.config.productionTip = false
// Vue.filter('formatDate',(value,formatstr='YY年MM月dd日 hh时mm分ss秒')=>{
Vue.filter('formatDate',(value,formatstr=' MM月dd日')=>{
  let d = new Date(value);
  let dobject={
    'Y+':d.getFullYear(),
    'M+':d.getMonth()+1,
    'd+':d.getDate(),
    'h+':d.getHours(),
    'm+':d.getMinutes(),
    's+':d.getSeconds(),
  }
  for(let key in dobject){
    let reg = new RegExp(key,'g');
    let val = dobject[key];
    val = val<10? '0'+val:val;
    formatstr=formatstr.replace(reg,val);
  }
  return formatstr
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
